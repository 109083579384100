import React from 'react';
import { Box, CardMedia, Typography,Card } from '@mui/material';
const Demos = () => {
  return (
    <Box sx={{mt:'10%', mb:'10%'}}>
<Typography  textAlign='center' variant='h3'>Demos</Typography>

<Box sx={{ margin:'10% auto' ,display:'flex', flexDirection:{xs:'column', sm:'row'}, justifyContent:'space-around', alignItems:'center' }}>
<Card sx={{width:'25%'}}>
  <Typography variant='h5'>Demo1</Typography>
  <CardMedia></CardMedia>
</Card>
</Box>
    </Box>
  );;
};

export default Demos;
