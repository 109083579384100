import React from "react";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "./carousel.css";
const Testimonials = () => {
  let testimonials = [
    {
      name: "Mose",
      image: "/images/dogPic1.jpeg",
      para: "“Daniel was recommended to us through our vet, and I'm so happy we lucked into the referral!  We have been working with Daniel since our Rottie/Mastiff (\"Mose\") was a puppy and he has been instrumental in helping our family understand Mose's behaviour, and raise a happy and well adjusted dog.  Daniel has a knack for  breaking down the rationale behind the methodology, and explaining how to best work with Mose in a way our family (including our girls, 8 and 13) can easily follow.  Highly recommend!”",
    },
    {
      name: "Bijou",
      image: "/images/dogPic2.jpeg",
      para: "“With Daniel’s help we were able to considerably diminish the extensive and continuous barking that our 7 year old Maltese was doing.  As we live in a condo downtown, she was extremely nervous and uneasy being outdoors, let alone socializing with other dogs. She was also very nervous and afraid of all the noises around her. Nonetheless, with training, Daniel was successful in making her feel more comfortable and confident in different outdoor surroundings, even so far as interacting and  taking walks with a Rottweiler.”",
    },
    {
      name: "Ollie",
      image: "/images/dogPic5.jpeg",
      para: "“We are first time dog owners and Daniel has made sense of something that felt very overwhelming. We were given easy to follow methods that have made an immediate difference, and we continue to learn more each session. Highly recommend Daniel!”",
    },
    {
      name: "Neo",
      image: "/images/dogPic6.jpeg",
      para: "“In the very first session, Daniel explained to us his philosophy of training puppies by positive reinforcement. Daniel has helped Neo with cues and has helped us in understanding Neo's personality.  He has always been extremely gentle and patient with Neo. Daniel has been very involved in Neo's growth. He is very approachable and encourages questions even outside of scheduled sessions. Since Neo was a puppy and was not getting enough socialization, Daniel set up some playdates with matching personality puppies. Thank you Daniel for working with us and helping make Neo a better dog.”",
    },
    {
      name: "Smudge",
      image: "/images/dogPic3.jpeg",
      para: "“I was at a loss on how to properly train Smudge, as I've never had a dog before. From the first training session, I could just tell how much Daniel enjoyed what he does and he had so much knowledge to share!  He was extremely accommodating with my busy schedule, the classes were fairly priced and he would even quickly respond to any questions I had outside of our training hours.”",
    },
    {
      name: "Korra",
      image: "/images/dogPic4edit.jpg",
      para: "“I’m very happy with the training Daniel did with Korra.  He treated Korra like she was his own dog and used methods that Korra responded to quickly.  Now I can be confident in the training I do with Korra in my own time and, as a result, our bond is stronger.”",
    },
    
  ];
  return (
    <Box
      sx={{
        mt: "10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: { xs: "15%", lg: '5%' },
      }}
    >
      <Typography textAlign="center" variant="h3">
        Testimonials
      </Typography>

      <Carousel  autoplay='true' interval={15000} className="carousel">
        {testimonials.map((testimonial, i) => (
          <Card
            sx={{
              width: "95%",
              margin: "1% auto",
              display: "flex",
              flexDirection: "column",
              boxShadow: "2",
              padding: "1%",
              alignItems: "center",
              justifyContent:'center',
              // zIndex:0,
              minHeight: '60vh',
              
            }}
            key={i}
          >
            <CardMedia
              image={`${testimonial.image}`}
              sx={{
                width: { xs: "200px", md: "500px" },
                height: { xs: "200px", md: "500px" },
                borderRadius: "50%",
                mb: "5%",
              }}
            />
            <Typography
              fontSize={{ lg: "1.3rem", xl: "2rem" }}
              gutterBottom
              variant="h6"
            >
              {testimonial.name}
            </Typography>

            <Typography
              fontSize={{md:'1rem', lg: "1rem", xl: "1.3rem" }}
              variant="subtitle2"
              sx={{ padding: "30px" }}
            >
              {testimonial.para}
            </Typography>
          </Card>
        ))}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
