import { Box, Typography, Link, Divider } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { Email } from "@mui/icons-material";

const Header = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      padding:'2%'
      }}
    >
      <Typography  align={"center"} variant="h1" padding={"8%"} pb={'5%'} >
        Pup's Perspective
      </Typography>
      <Typography
        
        textAlign={"center"}
        margin='2%'
        marginBottom="5%"
        variant="h4"
        fontStyle={"italic"}
        fontWeight={"500"}
        // fontSize={{xl:'3rem'}}
      >
        Simple and Effective Dog Training
      </Typography>

    </Box>
  );
};

export default Header;
