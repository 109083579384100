import React from "react";
import { Box, Typography, } from "@mui/material";

const Rates = () => {
  return (
    <Box
      sx={{
        mt: "10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: { xs: "15%", lg: "5%" },
        padding:'1%'
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-around",
          alignItems: { xs: "center", lg: "flex-start" },
          mt: "4%",
        }}
      >
       <Box sx={{ 
          width:"50%",
          display:"flex",
          flexDirection:"column",
          justifyContent: "space-around",
          alignItems: { xs: "center", lg: "center" },
          }}>
        <Box
          component="img"
          src="/images/w2.jpeg"
          sx={{ width: { xs: "100%", lg: "55%", xl: "40%" }, boxShadow: 6, mb:'10%', }}
        />
        <Box
          component="img"
          src="/images/w4.jpeg"
          sx={{ width: { xs: "100%", lg: "68%", xl: "45%" }, boxShadow: 6, mb:'10%' }}
        />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width:{xs:'100%',lg:'30%'},
         
          }}
        >
          <Typography textAlign="center" variant="h3" m={"10%"}>
            Rates
          </Typography>

          <Box
            sx={{
              width:"100%",
              margin: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign:"center",
              height:"100vh",
              justifyContent:"space-around"
              
            }}
          >
            <Box
              sx={{
                mb: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                
              }}
            >
              <Typography variant="h4">Dog Walk & Train</Typography>
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                $50/walk
              </Typography>

              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                5 Walk Package - $200
              </Typography>
            </Box>
            <Box
              sx={{
                mb: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Private Training Sessions </Typography>
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                $85/session
              </Typography>

              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                3 Session Package - $230
              </Typography>

              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                6 Session Package - $410
              </Typography>
            
            </Box>
            <Box
              sx={{
                mb: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Dog Boarding </Typography>
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "1rem", lg: "1rem", xl: "1.5rem" }}
              >
                $100/day
              </Typography>
            </Box>
       
          </Box>
        </Box>

        <Box sx={{ 
          width:"50%",
          display:"flex",
          flexDirection:"column",
          justifyContent: "space-around",
          alignItems: { xs: "center", lg: "center" },
          }}>
        <Box
          component="img"
          src="/images/w3.jpeg"
          sx={{ width: { xs: "100%", lg: "75%", xl: "60%" }, boxShadow: 6, mb:'10%', }}
        />
        <Box
          component="img"
          src="/images/w1.jpeg"
          sx={{ width: { xs: "100%", lg: "90%", xl: "60%" }, boxShadow: 6, mb:'10%' }}
        />
        </Box>
      </Box>
    </Box>
  );
};

export default Rates;
