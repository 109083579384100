import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Send } from '@mui/icons-material';
const Contact = () => {
  return (
    <Box sx={{
      mt:'10%',
      mb:{xs:'15%',lg:'5%'}
    }}>
<Typography  textAlign='center' variant='h3'>Contact Me</Typography>
<Box sx={{mt:'5%',display:'flex', flexDirection:{xs:'column',md:'row'}, justifyContent:'space-evenly', alignItems:'center'}}>

<Box component='img' src='/images/giada1.jpeg' sx={{width:{xs:'75%',md:'35%'}, mb:'5%', boxShadow:6}}/>

<Box sx={{width:{xs:'80%',md:'51%'},}} component='form'  autoComplete='off' action="https://formsubmit.co/5c9c13b6c096a7d3988580903869eb3e" method="POST">
<Box  sx={{
                m: {xs:'5%', md:0},
                display: "flex",
                flexDirection: "column",
                justifyContent:'flex-start'
              }} >
              <Typography
                variant="subtitle1"
                fontSize={{ lg: "1rem", xl: "1.5rem" }}
                // m='2%'
                
                
              >
                Prepare in advance and be proactive about integrating your new
                canine family member harmoniously.
                <br/>
                Schedule a consultation
                before you get your dog and get 50% off your first training
                session.
              </Typography>
            </Box>



<Box sx={{ display:'flex', justifyContent:'space-between', mt:'15%'}} >
<TextField   type="text" name="name" sx={{width:'49%'}} label='Name' variant='outlined' required />
<TextField type="email" name="email" sx={{width:'49%'}}label='Email' variant='outlined' required />
</Box>
<TextField sx={{m:'3% auto',}} type='text' name='message' label='Message' variant='outlined' fullWidth multiline rows={15} required />
<Button type='submit' sx={{color:'black',"&:hover":{bgcolor:'black', color:'white'},
width:'100%', margin:'1% auto', mb:'3%'}} endIcon={<Send/>}>Send</Button>

</Box>
</Box>
    </Box>
  );;
};

export default Contact;
