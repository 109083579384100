import {
  
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { React, } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { Email } from "@mui/icons-material";
import { Link as ReactLink } from "react-router-dom";
const Footer = () => {
  // const [value, setValue] = useState([]);

  return (


    <BottomNavigation
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,

        height: { xs: "40px", lg: "60px", xl: "70px" },

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        mt: "20px",
        bgcolor: "black",
        opacity: 1,
        padding: '8px',
        
      }}
    >
      <BottomNavigationAction
        href="tel:+1 647-227-0354"
        sx={{
          color: "white",
          "& .MuiBottomNavigationAction-label": {
            fontSize: { xs: "1rem", lg: "1.3rem" },
          },
          // "&:hover":{color:'red'}
        }}
        label="+1 647-227-0354"
        icon={<PhoneIcon sx={{ color: "white" ,"&:hover":{color:'gold'}  }} />}
      />

      <BottomNavigationAction
        LinkComponent={ReactLink}
        to="/contact"
        sx={{
          color: "white",
          "& .MuiBottomNavigationAction-label": {
            fontSize: { xs: "1rem", lg: "1.3rem" },
          },
        }}
        label="pupsperspective@gmail.com"
        icon={<Email sx={{ color: "white","&:hover":{color:'gold'}
      }} />}
      />
    </BottomNavigation>
  );
};

export default Footer;
