import { Stack, Divider, Typography, Tabs, Tab } from "@mui/material";

import React from "react";
import { Link } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";

const Navbar = () => {
  return (
    <Stack
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",

        flexDirection: { xs: "column", sm: "row" },

        alignItems: "center",
      }}
    >
      <Typography  fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
            
          }}
          component={ReactLink}
          to={"/"}
        >
          Home
        </Link>
      </Typography>
      <Typography fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
          }}
          component={ReactLink}
          to={"/services"}
        >
          Services
        </Link>
      </Typography>
      <Typography fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
          }}
          component={ReactLink}
          to={"/rates"}
        >
          Rates
        </Link>
      </Typography>
      {/* <Typography fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
          }}
          component={ReactLink}
          to={"/demos"}
        >
          Demo's
        </Link>
      </Typography> */}
      <Typography fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
          }}
          component={ReactLink}
          to={"/testimonials"}
        >
          Testimonials
        </Link>
      </Typography>
      <Typography fontSize={{ xl: "2rem" }} variant="h6">
        <Link
          sx={{
            textDecoration: "none",
            color: "black",
            "&:hover": { borderBottom: "solid 1px black" },
            "&:focus": { borderBottom: "solid 2px black" },
          }}
          component={ReactLink}
          to={"/contact"}
        >
          Contact
        </Link>
      </Typography>
    </Stack>
  );
};
export default Navbar;
