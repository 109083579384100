import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  
} from "@mui/material";
import { grey } from '@mui/material/colors'
import { Routes, Route } from "react-router-dom";

import React from "react";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Rates from "./components/Rates";
import Demos from "./components/Demos";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import About from "./components/About";
import Footer from "./components/Footer";

let theme = createTheme({
  palette:{
    primary:{
      main: grey[900]
      
    }
  }
  ,
  typography: {
    fontFamily: "Cormorant",
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <Navbar />
        
        <Routes>
          <Route path="/" element={<About />} />
          <Route  path="/services" element={<Services />} />
          <Route path="/rates" element={<Rates />} />
          <Route path="/demos" element={<Demos />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
