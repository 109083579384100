import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Box sx={{ mt: "10%", mb: { xs: "15%", lg: "8%" } }}>
      <Grid sx={{ margin: "1% auto" }} container>
        <Grid item sm={8} padding={"5%"}>
          <Box
            component="img"
            src="/images/w5.jpeg"
            sx={{ width: "100%", boxShadow: 6 }}
          />
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            p: "7%",
            pl: { sm: "1%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            fontStyle={"italic"}
            textAlign={"center"}
            fontSize={{ lg: "1.3rem", xl: "2rem" }}
            variant={"h6"}
          >
            “By understanding the science of how dogs learn and communicate, we gain invaluable insights into the nature of behavior modification and how to best meet our dogs’ needs.” - Daniel Gri, KPA CTP
          </Typography>
          <Typography textAlign="right" variant="h6">
            - Daniel Gri, KPA CTP
          </Typography>
        </Grid>
      </Grid>
      <Typography textAlign="center" variant="h3">
        About
      </Typography>
      <Typography
        fontSize={{ lg: "1.2rem", xl: "1.8rem" }}
        variant="subtitle1"
        paragraph
        sx={{ margin: "2% 15%" }}
      >
        Daniel Gri is committed to providing dog lovers a greater quality of
        life and a deeper bond with their pets. By applying his experience as a
        bilingual Karen Pryor Academy Certified Training Partner and background in psychology at
        the University of Toronto, he is equipped to teach dog owners the
        effectiveness of positive reinforcement for effective training in clear,
        simple steps. He offers personalized training plans, walking schedules,
        and boarding services.
      </Typography>
    </Box>
  );
};

export default About;
